const defaultTheme = require('tailwindcss/defaultTheme');
const colorConstants = require('./jiraaf-ui/helpers/color-constants');
const { default: typography } = require('./jiraaf-ui/helpers/typography');
const plugin = require('tailwindcss/plugin');
const backgroundConstants = require('./helpers/backgroundConstants');

module.exports = {
    content: [
        './pages/*.js',
        './pages/**/*.js',
        './pages/**/**/*.js',
        './pages/**/**/**/*.js',
        './pages/**/**/**/**/*.js',
        './atoms/*.js',
        './molecules/*.js',
        './molecules/**/*.js',
        './organisms/*.js',
        './organisms/**/*.js',
        './templates/*.js',
        './templates/**/*.js',
        './components/**/*.js',
        './components/**/**/*.js',
        './components/**/**/**/*.js',
        './components/**/**/**/**/*.js',
        './jiraaf-ui/**/*.js',
    ],
    options: {
        whitelist: ['.grid', '.grid-cols-3', '.gap-x-5'],
        safelist: [
            {
                variants: ['.text-5xl', 'sm', 'lg', 'xl', 'md'],
            },
        ],
    },
    theme: {
        colors: {
            /* IMPORTANT: New Colors from design system should be 
            added to jiraaf-ui/helpers/color-constants.js */
            ...colorConstants,
        },
        extend: {
            screens: {
                xs: { min: '425px', max: '640px' },
                maxScreen: '1440px',
                ...defaultTheme.screens,
            },
            zIndex: {
                '-1': '-1',
            },
            listStyleType: {
                roman: 'lower-roman',
                alpha: 'lower-alpha',
            },
            grayscale: {
                100: '100%',
            },
            inset: (theme) => ({
                '1/5': '20%',
                '1/10': '10%',
                '1/20': '4.3%',
                10.5: '2.625rem',
                8.5: '2.125rem',
                24.5: '6.3rem',
                200: '50rem',
            }),
            margin: (theme) => ({
                22: '5.5rem',
                15: '3.75rem',
                13: '2.188rem',
                15.5: '4.688rem',
            }),

            padding: (theme) => ({
                'screen-90': '80vh',
                'screen/1.3': '75vh',
                'screen/2': '50vh',
                'screen/3': 'calc(100vh / 3)',
                'screen/4': 'calc(100vh / 4)',
                'screen/5': 'calc(100vh / 5)',
                4.5: '1.125rem',
                '52px': '3.25rem',
                18: '4.5rem',
                53: '13.25rem',
                22: '5.5rem',
                '18px': '1.125rem',
                '34px': '2.125rem',
                17: '4.25rem',
                19: '4.75rem',
                26: '6.5rem',
                14: '3.625rem',
                34: '8.5rem',
                46: '11.813rem',
                62: '15.875rem',
                18.5: '4.625rem',
                114.5: '28.625rem',
            }),
            height: (theme) => ({
                75: '18.75rem',
                200: '50rem',
                127: '508px',
                138: '552px',
                121: '30.25rem',
                107: '26.75rem',
                '95%': '95%',
                85: '21.25rem',
                section: '92vh',
            }),
            borderWidth: (theme) => ({
                1: '1px',
            }),
            borderRadius: (theme) => ({
                '10px': '0.625rem',
                '7px': '0.438rem',
                '20px': '1.125rem',
                '30px': '1.875rem',
                '40px': '2.5rem',
                '80px': '5rem',
            }),
            maxWidth: {
                60: '12rem',
                70: '17.5rem',
                316: '79rem',
                745: '745px',
                1200: '1200px',
                'site-width': '1440px',
            },
            boxShadow: {
                0.5: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                0.05: '0px 14px 34px 0px rgba(0, 0, 0, 0.05)',
            },
            fontFamily: {
                paragraph: ['GeneralSans', 'sans-serif'],
                heading: ['GeneralSans', 'sans-serif'],
            },
            spacing: {
                360: '5.625rem',
                122: '30.625rem',
            },
            fontWeight: (theme) => ({
                normal: '400',
                aboveNormal: '450',
            }),
            fontSize: (theme) => ({
                'title:size': [33, 36],
                '18px': '0.938rem',
                '28px': '1.75rem',
                '32px': '2rem',
            }),
            container: {
                padding: {
                    xl: '2rem',
                },
            },
            width: {
                250: '1000',
                333: '83rem',
                120: '30rem',
                140: '35rem',
                316: '79rem',
                1010: '63.125rem',
            },
            minWidth: {
                50: '12.5rem',
                55: '13.75rem',
                85.75: '21.438rem',
                150: '37.5rem',
                280: '70rem',
            },
            minHeight: {
                12: '3rem',
                25: '6.25rem',
                400: '400px',
                600: '600px',
            },
            maxHeight: {
                section: '80vh',
            },
            lineHeight: {
                32: '8rem',
            },
            gridTemplateColumns: {
                13: 'repeat(13,minmax(0,1fr))',
                14: 'repeat(14,minmax(0,1fr))',
                16: 'repeat(16,minmax(0,1fr))',
                18: 'repeat(18,minmax(0,1fr))',
                20: 'repeat(20,minmax(0,1fr))',
                21: 'repeat(21,minmax(0,1fr))',
                'investors-table':
                    'minmax(0, 1fr) minmax(6.3rem, 1fr) minmax(0, 1fr) minmax(4rem, 1fr) repeat(5,minmax(0, 1fr)) 1.4rem',
                'investors-table-tab':
                    'minmax(7rem, 1.1fr) repeat(4,minmax(7rem, 1.1fr)) minmax(0rem, 0.5fr)',
                'investors-table-mobile': 'minmax(8rem, 1.2fr) minmax(0, 1fr)',
                'opportunities-table':
                    'minmax(10rem, 1fr) repeat(2,minmax(7.5rem, 1fr)) repeat(7,minmax(0, 0.7fr)) minmax(0, 0.5fr)',
                'opportunities-table-mobile':
                    'minmax(10rem, 1fr) minmax(0, 0.5fr)',
                'invited-table-tab':
                    'minmax(0rem, 1fr) repeat(2,minmax(8rem, 1.2fr)) minmax(0rem, 0.8fr)',
                'invited-table-mobile': 'minmax(8rem, 1fr) minmax(0, 0.8fr)',
            },
            backgroundImage: backgroundConstants,
        },
    },
    plugins: [
        plugin(({ addUtilities }) => {
            addUtilities(typography);
        }),
    ],
};
