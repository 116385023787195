import { getAuth, removeAuth } from '@services/identity.service';
import { logoutUser } from '@services/users.service';
import { OpportunityTypes } from '@ui/helpers/opportunities/opportunity-types';
import { sumBy } from 'lodash';
import Moment from 'moment';
import router from 'next/router';
import { opportunityClosedStates } from './constants';
import { reInvestmentLineStatusEnums } from '@ui/helpers/enums/reinvestmentLineStatusEnums';
import { reInvestmentStatusEnums } from '@ui/helpers/enums/reinvestmentStatusEnums';
export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const parseData = (data) => {
    return JSON.parse(data);
};

export const getNumberAsCurrency = (
    number,
    locale = 'en-IN',
    currency = 'INR'
) => {
    return number?.toLocaleString(locale, {
        style: 'currency',
        currency,
    });
};

export const mailToGmail = (value) =>
    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberWithComma = (number) => {
    return number && number.toLocaleString('en-IN');
};

export const scrollToTargetAdjusted = (reference, headerOffset = 64) => {
    let elementPosition = reference.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInitials = (fullName) => {
    let names = fullName.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getNumberInLakhAndCrore = (value) => {
    const updatedValue = String(value).slice(0, 4);
    if (value >= 100000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Cr`;
    }
    if (value < 100000000 && value >= 10000000) {
        const valueToShow =
            updatedValue[1] === '0' && updatedValue[2] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000 && value >= 1000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Lakh(s)`;
    }
    if (value < 1000000 && value >= 100000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Lakh(s)`;
    }
    return getNumberWithComma(value);
};

export const amountToBeMultipleOf = (amount, investmentMultiple) =>
    amount % investmentMultiple === 0;
// DEPRICATED!
export const isAssetBackedLeasingOpportunity = (opportunityType) => {
    return opportunityType === 'AssetBackedLeasing';
};
// DEPRICATED!
export const isInvoiceDiscountingOpportunity = (opportunityType) => {
    return opportunityType === 'InvoiceDiscounting';
};
// DEPRICATED!
export const isCorporateDebtOpportunity = (opportunityType) => {
    return opportunityType === 'CorporateDebt';
};
// DEPRICATED!
export const isVentureDebtOpportunity = (opportunityType) => {
    return opportunityType === 'VentureDebt';
};
// DEPRICATED!
export const isTreasuryBillOpportunity = (opportunityType) => {
    return opportunityType === 'TreasuryBill';
};

export const getDecimalNumberWithComma = (number, decimalPlace = 4) => {
    number = parseFloat(number).toFixed(decimalPlace);
    return number.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};

/**
 *  When we use  Moment for date format conversion, 
 *  we need to mention the current format of the date and in which format we want to convert.
 * 
 *  because on Chrome browser, date format conversion works fine without ,
 *  giving the current format of the date.
 *  
 *  But for Firefox and other browsers, 
 *  Moment will return an invalid date if the date is in string format.
 * 
 *  We can avoid invalid date errors by mentioning the date's current format.
 *  
 */
export const formatDate = (date, format = 'MMM D, YYYY') => {
    if (date && Moment(date).isValid()) {
        return Moment(date).format(format);
    } else if (date && Moment(date, 'MM-DD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DD-YYYY').format(format);
    } else if (date && Moment(date, 'MM-DDD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DDD-YYYY').format(format);
    }
    return '';
}

export const checkInProcessStatus = (repaymentDate, gracePeriod = 0) => {
    let today = Moment(Moment().utcOffset(330).format('YYYY-MM-DD'));
    let repaymentDateInFormat;

    if (Moment(repaymentDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
        repaymentDateInFormat = Moment(
            repaymentDate,
            'YYYY-MM-DDTHH:mm:ss.SSSZ'
        ).format('YYYY-MM-DD');
    } else if (Moment(repaymentDate, 'DD-MMM-YYYY', true).isValid()) {
        repaymentDateInFormat = Moment(repaymentDate, 'DD-MMM-YYYY').format(
            'YYYY-MM-DD'
        );
    } else {
        repaymentDateInFormat = Moment(repaymentDate, 'MM-DD-YYYY').format(
            'YYYY-MM-DD'
        );
    }

    const gracePeriodDate = Moment(repaymentDateInFormat)
        .add(gracePeriod, 'days')
        .format('YYYY-MM-DD');

    if (
        today.isSameOrBefore(gracePeriodDate) &&
        today.isAfter(repaymentDateInFormat)
    ) {
        return 'In Process';
    }
    return 'Delayed';
};

export const getPaymentStatus = (payment, gracePeriod = 0) => {
    if (payment.status === 'Delayed') {
        return checkInProcessStatus(payment.date, gracePeriod);
    }
    return (payment?.status === 'UpComing' || !payment?.status) ? 'Upcoming' : payment.status;
};

export const getPaymentStatusForAssetBackedLeasingAndInvoiceDiscounting = (
    payment,
    gracePeriod = 0
) => {
    if (
        payment.status === 'Received' ||
        payment.repaymentTransferRecords.length
    ) {
        if (
            isAllTransferSuccessful(payment.repaymentTransferRecords) ||
            payment.type === 'Refund'
        ) {
            return 'Repaid';
        } else {
            return 'Repayment in progress';
        }
    }
    if (payment.status === 'Delayed') {
        return checkInProcessStatus(payment.date, gracePeriod);
    }
    return (payment?.status === 'UpComing' || !payment?.status) ? 'Upcoming' : payment.status;
};

export const downloadUrl = async (url) => {
    var link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const getMinInvestmentForOpportunity = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.VENTURE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
            return getNumberWithComma(
                Math.round(
                    opportunityClosedStates.includes(opportunity.state)
                        ? opportunity.initialFaceValue *
                        opportunity.minInvestmentUnit
                        : opportunity.dailyDebenturePrice *
                        opportunity.minInvestmentUnit
                )
            );
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return getNumberWithComma(opportunity.minInvestmentAmount);
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return getNumberWithComma(opportunity.minInvestmentAmount);
    }
};

export const getMaxInvestmentForOpportunity = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return getNumberString(opportunity.maxInvestmentAmount);
        default:
            return null;
    }
};

export const getNumberString = (number) => {
    return number ? number.toLocaleString('en-IN') : number;
};

export const getNumberWithFourdecimal = (number) => {
    if (typeof number === 'string') {
        return Number(number).toFixed(4);
    }
    return number.toFixed(4);
};

export const stringifyData = (data) => {
    return JSON.stringify(data);
};

export const startTimer = (expiry, setMins, setSecs) => {
    let myInterval = setInterval(() => {
        let diff = Math.abs(expiry - new Date().getTime()) / 1000;
        setMins(String(Math.floor(diff / 60) % 60).padStart(2, '0'));
        setSecs(String(Math.floor(diff % 60)).padStart(2, '0'));
    }, 1000);
    return () => {
        clearInterval(myInterval);
    };
};

export const getMobileWithoutCountryCode = (mobile, countryCode) => {
    return mobile.replace(countryCode.substring(1), '');
};

export const getQueryParamsFromUrl = (search, query) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(query);
};

export const isUndefined = (value) => {
    return value === undefined;
};

export const getFormattedPhoneNumber = (number) => {
    const countryCode = number?.slice(0, 2);
    const phoneNumber = number?.slice(2);

    return `+${countryCode} ${phoneNumber}`;
};

export const getInvestorStatusStyles = (status) => {
    switch (status) {
        case 'ManualVerificationRequired':
            return 'bg-semantic-warning-light text-semantic-warning-base';
        case 'Verified':
            return 'bg-semantic-success-light text-semantic-success-base';
        case 'Rejected':
            return 'bg-semantic-error-light text-semantic-error-base';
        default:
            return 'bg-semantic-info-light text-semantic-info-base';
    }
};

export const getInvestorStatusValues = (status) => {
    switch (status) {
        case 'ManualVerificationRequired':
            return 'Verification Pending';
        case 'DigioVerified':
            return 'Digio Verified';
        default:
            return status;
    }
};

export const getInvestmentStatusStyles = (status) => {
    switch (status) {
        case 'Active':
            return 'bg-semantic-info-light text-semantic-info-base max-w-fit';
        case 'Fully Repaid':
            return 'bg-semantic-success-light text-semantic-success-base max-w-fit';
        case 'Delayed':
            return 'bg-semantic-error-light text-semantic-error-base max-w-fit';
        case 'In Process':
            return 'bg-semantic-warning-light text-semantic-warning-base max-w-fit';
        default:
    }
};

export const getSheetData = (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map((row) => {
        return fields.map((fieldName) => {
            return row[fieldName] ? row[fieldName] : '';
        });
    });
    sheetData.unshift(header);
    return sheetData;
};

export const getMaskedData = (data, charsToShowFromEnd = 4, mask = 'X') => {
    if (data && data.length > charsToShowFromEnd) {
        return (
            data
                ?.slice(0, data.length - charsToShowFromEnd)
                .replace(/./g, mask) +
            data?.slice(data.length - charsToShowFromEnd)
        );
    }

    return data;
};

export const logout = async () => {
    await logoutUser(getAuth());
    removeAuth();
    router.push('/');
};

export const hasMultipleUsers = (individuals) => individuals.length >= 2;

export const unwrapMultipleUsers = (investors) => {
    let updatedInvestorsResponse = {
        entity: { rows: [], count: 0 },
    };
    investors.entity.rows.map((investor) => {
        if (hasMultipleUsers(investor.referee.individuals)) {
            investor.referee.individuals.map((individual) => {
                let updatedInvestor = {
                    ...investor,
                    referee: {
                        ...investor.referee,
                        individuals: [],
                    },
                };
                updatedInvestor.referee.individuals = [{ ...individual }];
                updatedInvestorsResponse.entity.rows.push(updatedInvestor);
            });
        } else updatedInvestorsResponse.entity.rows.push(investor);
    });
    return updatedInvestorsResponse;
};

export const getTenure = (startDate, endDate) => {
    if (!startDate)
        startDate = Moment.utc().utcOffset('+05:30').format('DD MMM YYYY');
    else
        startDate = Moment.utc(startDate)
            .utcOffset('+05:30')
            .format('DD MMM YYYY');
    endDate = Moment.utc(endDate).utcOffset('+05:30').format('DD MMM YYYY');
    const months = Math.round(
        Moment(endDate).diff(Moment(startDate), 'days') / 30
    );
    if (months < 6) {
        const days = Math.round(
            Moment(endDate).diff(Moment(startDate), 'days')
        );
        return days + ' D';
    }
    if (months > 24) return ((months / 12) | 0) + ' Y ' + (months % 12) + ' M';
    return months + ' M';
};

/**
 *  When we use  Moment for date format conversion, 
 *  we need to mention the current format of the date and in which format we want to convert.
 * 
 *  because on Chrome browser, date format conversion works fine without ,
 *  giving the current format of the date.
 *  
 *  But for Firefox and other browsers, 
 *  Moment will return an invalid date if the date is in string format.
 * 
 *  We can avoid invalid date errors by mentioning the date's current format.
 *  
 */

export const getValidDate = (date, format = 'DD-MMM-YYYY') => {
    if (date && Moment(date).isValid()) {
        return Moment.utc(date).utcOffset('+05:30').format(format)
    } else if (date && Moment(date, 'MM-DD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DD-YYYY').utc().utcOffset('+05:30').format(format)
    } else if (date && Moment(date, 'MM-DDD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DDD-YYYY').utc().utcOffset('+05:30').format(format)
    }
    return '';
}

export const getDurationBetweenDates = (startDate, endDate) => {
    if (!startDate) startDate = Moment().utc().format();
    const months = Math.round(
        Moment(endDate).diff(Moment(startDate), 'days') / 30
    );
    if (months < 6) {
        const days = Math.round(
            Moment(endDate).diff(Moment(startDate), 'days')
        );
        return days + ' D';
    }
    if (months > 24) return ((months / 12) | 0) + ' Y ' + (months % 12) + ' M';
    return months + ' M';
};

export const removeAllSpaces = (string) => string.replace(/\s/g, '');

export const getPostTaxRepaymentAmount = (repaymentTransferRecords) => {
    return repaymentTransferRecords?.length
        ? sumBy(repaymentTransferRecords, 'principal') +
        sumBy(repaymentTransferRecords, 'postTaxInterest') +
        sumBy(repaymentTransferRecords, 'postTaxPenalCharges')
        : 0;
};

export const isAllTransferSuccessful = (repaymentTransferRecords) => {
    return (
        repaymentTransferRecords &&
        repaymentTransferRecords.every(
            (repayment) => repayment.status === 'TransferSuccessful'
        )
    );
};

export const getInvestorBadgeStyles = (status) => {
    switch (status) {
        case 'Manual Verification Pending':
            return 'bg-semantic-warning-light  text-xs text-semantic-warning-base hover:line-clamp-none line-clamp-3 text-center';
        case 'Verified':
            return 'bg-semantic-success-light  text-xs text-semantic-success-base hover:line-clamp-none line-clamp-1 text-center';
        case 'Rejected':
            return 'bg-semantic-error-light  text-xs text-semantic-error-base hover:line-clamp-none line-clamp-1 text-center';
        case 'Invested':
            return 'bg-purple-50  text-xs text-purple-300 text-xs hover:line-clamp-none line-clamp-1 text-center';
        default:
            return 'bg-semantic-info-light text-xs text-semantic-info-base hover:line-clamp-none line-clamp-1 text-center';
    }
};

export const getExcelIndianCurrencyFormat = () => {
    return '[>=10000000]##\\,##\\,##\\,##0;[>=100000] ##\\,##\\,##0;##,##0.00';
};

export const reloadAuthenticatedTabs = () => {
    const channel = new BroadcastChannel('logout-event');
    channel.postMessage('logout');
    channel.close();
};

export const listenBroadCastMessage = (message, type = 'reload', router) => {
    if (message === 'logout') {
        removeAuth();
        switch (type) {
            case 'reload':
                router.reload();
                break;
            case 'logout':
                router.push('/logout');
                break;
            default:
                router.reload();
        }
    }
};

export const saveItemToLocalStorage = (key, details) => {
    return localStorage.setItem(key, JSON.stringify(details));
};

export const getItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorageItemWithExpiration = (key, valueName, value, expirationInHours = 1) => {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + expirationInHours * 60 * 60 * 1000;
    const item = {
        [valueName]: value,
        expiryAt: expirationTime
    };
    saveItemToLocalStorage(key, item);
}

export const isTimeExpired = (item) => {
    if (!item?.expiryAt) {
        return true
    }
    const currentTime = new Date().getTime();
    return currentTime > item.expiryAt;
}

export const numberOfDaysBetweenTwodates = (
    date,
    currentDate = Moment().utc().utcOffset('+05:30').startOf('day')
) => {
    const goLiveDate = Moment(date)
        .utc()
        .utcOffset('+05:30')
        .startOf('day');
    const daysToGoLive = goLiveDate.diff(currentDate, 'days');
    return daysToGoLive;
};

export const maskEmail = (email) => {
    if (!email) return '';

    const [localPart, domain] = email.split('@');

    let maskedLocalPart;

    // If local part has only one or two characters, mask all
    if (localPart.length < 3) {
        maskedLocalPart = '*'.repeat(localPart.length);
    }
    // If local part has only three characters, mask all except first char
    else if (localPart.length === 3) {
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 1)}`;
    } else {
        // Masking the local part, leaving the first and last character visible
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 2)}${localPart[localPart.length - 1]}`;
    }
    // Reconstruct the email with the masked local part and the domain
    return `${maskedLocalPart}@${domain}`;
}

export const removeTrailingZeros = (value) => {
    const valueToShow = value.toFixed(2);
    /* Remove trailing zeros
        // 1.00 -> 1
        // 1.10 -> 1.1
        // 1.55 -> 1.55
        // 2.05 -> 2.05
    */
    return valueToShow.replace(/(\.0+|0+)$/, '');
};

export const handleKeyDown = (e, clearInput, onEnter) => {
    // If backspace is pressed and the whole input is selected
    if ((e.key === 'Backspace' || e.key === 'Delete') && e?.target?.selectionStart === 0 && e?.target?.selectionEnd === e?.target?.value?.length) {
        e.preventDefault(); // Prevent the default behavior
        // Clear only the number portion of the input
        clearInput && clearInput()
    }
    if (e.key === 'Enter') {
        onEnter ? onEnter() : e.preventDefault();
    }
};

export const getReInvestmentStatusBadgeStyles = (lineStatus, reinvestmentStatus) => {
    if(reinvestmentStatus === reInvestmentStatusEnums.PENDING && lineStatus === reInvestmentLineStatusEnums.ACTIVE_FROZEN){
        return 'info';
    }
    switch (lineStatus) {
        case reInvestmentLineStatusEnums.ACTIVE:
        case reInvestmentLineStatusEnums.ACTIVE_FROZEN:
            return "success";
        case reInvestmentLineStatusEnums.SUSPEND:
            return 'warning';
        case reInvestmentLineStatusEnums.IN_PROGRESS:
            return 'info';
        case reInvestmentLineStatusEnums.AUTO_REINVESTMENT_IN_PROGRESS:
            return 'special';
        case reInvestmentLineStatusEnums.CLOSED:
        case reInvestmentLineStatusEnums.SUSPEND_FROZEN:
            return 'light-gray';
        default:
            return 'light-gray';
    }
};

export const getReInvestmentStatusText = (lineStatus, reinvestmentStatus = null) => {
    if(reinvestmentStatus === reInvestmentStatusEnums.PENDING && lineStatus === reInvestmentLineStatusEnums.ACTIVE_FROZEN){
        return 'Looking for opportunity';
    }
    switch (lineStatus) {
        case reInvestmentLineStatusEnums.ACTIVE:
        case reInvestmentLineStatusEnums.ACTIVE_FROZEN:
            return 'Reinvest is turned on';
        case reInvestmentLineStatusEnums.SUSPEND:
            return 'Reinvest is turned off';            
        case reInvestmentLineStatusEnums.AUTO_REINVESTMENT_IN_PROGRESS:
            return 'Investor e-sign pending';
        case reInvestmentLineStatusEnums.CLOSED:
        case reInvestmentLineStatusEnums.SUSPEND_FROZEN:
            return 'Reinvest is closed';
        default:
            return 'Reinvest is closed';
    }
};