import { OtpInput } from '@ui/atoms/design-system';
import { otpLength } from '../helpers/constants';
import { useEffect, useRef, useState } from 'react';

const useOtpInput = ({ id = "mobileNumberConfirmationOtp", setMessage, shouldFocusByDefault = true }) => {
    const [otpValue, setOtpValue] = useState('');
    const inputRef = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);

    const onChangeOtpHandler = (otp) => {
        setMessage('');
        setError(false);
        setOtpValue(otp);
    };

    useEffect(() => {
        if (inputRef?.current && shouldFocusByDefault) {
            inputRef.current.focus();
        }
    }, [shouldFocusByDefault]);

    return {
        otpValue,
        inputRef,
        setOtpValue,
        onChangeOtpHandler,
        setDisabled,
        setError,
        OtpInputComponent: (
            <OtpInput
                id={id}
                value={otpValue}
                onChange={onChangeOtpHandler}
                disabled={disabled}
                hasError={error}
                inputRef={inputRef}
                otpLength={otpLength}
            />
        ),
    };
};

export default useOtpInput;
