import React, { memo, useState } from 'react';
import Badge from './Badge';

const TabNBody = memo(({ content, tabref, activeTab }) => {
    return (
        <div className={`${activeTab != tabref ? 'hidden' : ''}`}>
            {content}
        </div>
    );
});

const Tab = ({ children, isAdminUi }) => {
    const [activeTab, setActiveTab] = useState('tab1');

    const switchTab = (tabref, onTabSelect) => {
        setActiveTab(tabref);
        if (onTabSelect) {
            onTabSelect();
        }
    };
    var TabNav = ({ label, tabref, onTabSelect, disable }) => {
        return (
            <div className="shrink-0 flex-grow">
                <button
                    onClick={() =>
                        disable ? '' : switchTab(tabref, onTabSelect)
                    }
                    className={`py-3 px-4 max-h-12 my-2 sm:my-0 flex flex-col items-center justify-center rounded-md w-full text-sm lg:text-lg ${
                        activeTab == tabref
                            ? `focus:outline-none bg-primary-500 md:border-primary-500 font-medium text-gray-50 `
                            : `text-gray-500 border-gray-200 font-normal`
                    } ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    id={tabref}
                >
                    {label}
                    {disable && (
                        <Badge
                            className={
                                'text-xs mx-2 rounded-3xl inline-block bg-primary-50 text-primary-500'
                            }
                            content={'Coming Soon'}
                        />
                    )}
                </button>
            </div>
        );
    };

    return (
        <div className="mb-6 mt-3 sm:mx-3 md:ml-2">
            <nav className="flex flex-nowrap overflow-x-auto overflow-y-hidden mb-2 rounded-md top-14 lg:top-20 bg-gray-50">
                {children.map((element, i) => {
                    return (
                        <TabNav
                            key={`tabnavkey-${i}`}
                            label={element.props.label}
                            tabref={element.props.tabref}
                            disable={element.props.disable}
                            onTabSelect={element.props.onTabSelect}
                        />
                    );
                })}
            </nav>
            <div className="">
                {children.map((element, i) => {
                    return (
                        <TabNBody
                            activeTab={activeTab}
                            key={`tabbodykey-${i}`}
                            content={element.props.children}
                            tabref={element.props.tabref}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Tab;
