export const otpContextEnum = Object.freeze({
    AUTHENTICATION: "Authentication",
    MOBILE_VERIFICATION: "MobileVerification",
    EMAIL_VERIFICATION: "EmailVerification"
});

export const otpEntityTypeEnum = Object.freeze({
    MOBILE: 'Mobile',
    USER: 'User',
    IFA: 'Ifa',
    IFA_INVITE: 'IfaInvite'
});