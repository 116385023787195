import Image from '@atoms/Image';
import Text from '@atoms/Text';
import VerifyOtpCard from '@components/homePage/VerifyOtpCard';
import { HeadTagData } from '@helpers/headTagData';
import { getOpenConfigurationByEntityType } from '@services/configuration.service';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const LoginCard = dynamic(() => import('@components/homePage/LoginCard'));
const PotentialCard = dynamic(() => import('@components/homePage/PotentialCard'));
const CampaignPopup = dynamic(() => import('@components/homePage/CampaignPopup'), { ssr: false });

function Home({
  campaignConfig
}) {
    const [showVerifyOtpCard, setShowVerifyOtpCard] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [campaignPopupVisible, setCampaignPopupVisible] = useState(false);
    const [highlightLoginCard, setHighlightLoginCard] = useState(false);
    const isCampaignPopupVisible = campaignConfig?.value === 'V1';
    const [otpModes, setOtpModes] = useState([]);

    const ifaHomeData = [
        {
            title: 'Better risk-adjusted returns with varied tenures',
            icon: 'investment_growth.svg',
        },
        {
            title: 'Exclusive investment opportunities',
            icon: 'investment.svg',
        },
        {
            title: 'Robust credit evaluation & monitoring processes',
            icon: 'encrypt.svg',
        },
        {
            title: 'Enhanced investor experience',
            icon: 'portfolio.svg',
        },
        {
            title: 'Competitive rewards',
            icon: 'saving.svg',
        },
        {
            title: 'Seamless onboarding',
            icon: 'oboarding-exp.svg',
        },
    ];

    const closeCampaignPopup = (isClickedonExplored = false) => {
        setCampaignPopupVisible(false);

        // Highlighting login card for 3 seconds
        if (isClickedonExplored) {
            setHighlightLoginCard(true);

            setTimeout(() => {
                setHighlightLoginCard(false);
            }, 3000);
        }
    }

    return (
        <>
            <NextHeadTag {...HeadTagData?.['/']} />
            <main className="relative flex flex-col flex-1 mt-20 xl:mt-22 px-4 xl:px-0 xl:mx-[72px] xl:min-h-[90vh]">
                <section className="relative z-30 flex flex-col justify-between flex-1 pb-10 xl:pt-4">
                    <div className="hidden xl:block">
                        <Text
                            content="Unlock the potential of your client’s portfolio"
                            className="p2-semibold text-gray-900"
                        />
                        <Text
                            content="with our Fixed Income Investment opportunities"
                            className="p4-regular text-gray-500"
                        />
                    </div>
                    <div className="flex flex-col pb-12 xl:pb-0 items-center xl:items-start xl:flex-row-reverse xl:justify-around xl:gap-6 xl:mt-6 xl:h-[392px]">
                        <div>
                            {showVerifyOtpCard ? (
                                <VerifyOtpCard
                                    mobileNumber={mobileNumber}
                                    setMobileNumber={setMobileNumber}
                                    setShowVerifyOtpCard={setShowVerifyOtpCard}
                                    otpModes={otpModes}
                                />
                            ) : (
                                <LoginCard
                                    mobileNumber={mobileNumber}
                                    setMobileNumber={setMobileNumber}
                                    setShowVerifyOtpCard={setShowVerifyOtpCard}
                                    highlightLoginCard={highlightLoginCard}
                                    setOtpModes={setOtpModes}
                                />
                            )}
                        </div>
                        <div className="xl:hidden text-center mt-6 mb-4 md:mt-10 md:mb-6">
                            <Text
                                content="Unlock the potential of your client’s portfolio"
                                className="p4-semibold sm:p3-semibold md:p2-semibold text-gray-900"
                            />
                            <Text
                                content="with our Fixed Income Investment opportunities"
                                className="p5-regular sm:p4-regular text-gray-500"
                            />
                        </div>
                        <div className="w-full">
                            <ul className="grid md:grid-cols-2 gap-4">
                                {ifaHomeData.map((item) => (
                                    <PotentialCard cardData={item} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-1 mt-6 md:mt-10 xl:mt-0">
                        <div className="relative">
                            <div
                                className="w-full bg-gray-50 max-w-[892px] px-3 pt-8 pb-4 md:pt-[37px] md:pl-[38px] md:pb-6 md:pr-7 rounded-2xl"
                                style={{
                                    boxShadow:
                                        '0px 13px 40px 0px rgba(11, 7, 110, 0.10)',
                                }}
                            >
                                <Text
                                    content="Introduce alternative Fixed Income Investments to your industry colleagues & friends and earn Unlimited Cash Rewards! Login to explore more."
                                    className="p5-regular text-gray-900 text-center"
                                />
                            </div>
                            <div className="absolute w-[262px] h-[52px] -top-[26px] -translate-x-1/2 left-1/2">
                                <Image
                                    src={`/images/homepage/refer-and-earn.svg`}
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {campaignPopupVisible && isCampaignPopupVisible && (
                <CampaignPopup
                    show={campaignPopupVisible}
                    onClose={closeCampaignPopup}
                    onClick={() => closeCampaignPopup(true)}  // passing true for CTA
                />
            )}
        </>
    );
}

export default Home;


/*  
   This function will run on 
   1. Build time  
   2. Revalidates on every 24 hours from last build/revalidate time for the first user
*/
export const getStaticProps = async (ctx) => {
    const defaultCampaignConfig = {
        type: 'General',
        entityType: 'IfaHomepageCampaign',
        value: 'V1',
    };
    try {
        const response = await getOpenConfigurationByEntityType('IfaHomepageCampaign', ctx);
        if (!(response?.status && response?.entity)) {
            return {
                props: {
                    campaignConfig: defaultCampaignConfig,
                },
                revalidate: 86400,
            };
        }

        const campaignConfig = response.entity || defaultCampaignConfig;
        return {
            props: { campaignConfig },
            revalidate: 86400,
        };
    } catch (error) {
        return {
            props: { campaignConfig: defaultCampaignConfig },
            revalidate: 86400,
        };
    }
};