const Select = ({
    label,
    option,
    selected,
    error,
    setValue,
    disabled,
    ...property
}) => {
    const handleChange = (event) => {
        let { value } = event.target;
        setValue(value);
    };
    return (
        <>
            {label && <label className="mb-2">{label}</label>}
            <select
                className={`box-border w-full  bg-basicWhite border-1 border-gray-300 p-3 rounded-lg ${property.className}`}
                onChange={(e) => handleChange(e)}
                disabled={disabled}
                id={property.id}
            >
                {option}
            </select>
            {error && (
                <div className="mt-2 text-left text-semantic-error-base ">{error}</div>
            )}
        </>
    );
};

export default Select;
