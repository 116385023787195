import * as HttpService from "@services/http.service"

export const postWithOutAuth = (url, entity) => {
    return HttpService.postWithOutAuthDecorator(url, entity)
}

export const getWithOutAuth = (url) => {
    return HttpService.getWithOutAuthDecorator(url)
}

export const postWithAuth = async (url, entity, ctx) => {
    if(!ctx){
        console.log("For Server side calls, Please pass the context object.", url)
    }
    let response = await HttpService.postWithAuthDecorator(url, entity, getAuthCtx(ctx))
    if (response.token) {
        setCookies(ctx, response);
    }
    return response
}

export const getWithAuth = async (url, ctx) => {
    if(!ctx){
        console.log("For Server side calls, Please pass the context object.", url)
    }
    let response = await HttpService.getWithAuthDecorator(url, getAuthCtx(ctx))
    if (response.token) {
        setCookies(ctx, response);
    }
    return response
}

export const deleteWithAuth = async (url, entity, ctx) => {
    if(!ctx){
        console.log("For Server side calls, Please pass the context object.", url)
    }
    let response = await HttpService.deleteWithAuthDecorator(url, getAuthCtx(ctx))
    if (response.token) {
        setCookies(ctx, response);
    }
    return response
}

export const putWithAuth = async (url, entity, ctx) => {
    if(!ctx){
        console.log("For Server side calls, Please pass the context object.", url)
    }
    let response = await HttpService.putWithAuthDecorator(url, getAuthCtx(ctx))
    if (response.token) {
        setCookies(ctx, response);
    }
    return response
}

export const setCookies = (ctx, response) => {
    ctx.res.setHeader('set-cookie', `AUTH=${JSON.stringify(response.token)}; path=/`)
    ctx.req.cookies.AUTH = JSON.stringify(response.token)
}

export const getAuthCtx = (ctx) => {
    return JSON.parse(ctx.req.cookies.AUTH);
}
