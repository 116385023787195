import Image from '@atoms/Image';
import HeaderMenuItem from '@components/homePage/HeaderMenuItem';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { useState } from 'react';
import HamburgerMenuItem from '@components/homePage/HamburgerMenuItem';
import { brandName, menuItems } from '@helpers/constants';

const HomeNavbar = ({ onBoard }) => {
    const { height } = useWindowDimensions();
    const [menuOpen, setMenuOpen] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div
            className={`fixed flex left-0 right-0 top-0 z-50 justify-center w-full items-center py-4 h-12 md:h-16 text-basicWhite ${
                onBoard
                    ? 'bg-primary-500'
                    : 'bg-basicWhite xl:backdrop-blur-md backdrop-blur-2xl'
            } mx-auto`}
            style={{
                boxShadow: '0px 4px 54px rgba(0, 0, 0, 0.09)',
            }}
        >
            <div
                className={`flex items-center w-full ${
                    onBoard
                        ? 'justify-center lg:justify-start '
                        : 'justify-between'
                } max-w-site-width px-4 md:px-6 xl:px-18`}
            >
                <div className="w-[94px] h-5 md:w-[112px] md:h-6">
                    <Image
                        src={`/images/${
                            onBoard ? 'altGraaf-logo-white' : 'logo'
                        }.svg`}
                        alt={`${brandName}`}
                    />
                </div>
                {!onBoard && (
                    <>
                        <div className="flex items-center justify-between md:hidden">
                            <div className="flex items-center gap-x-8">
                                <div onClick={handleMenu}>
                                    <Image
                                        src={
                                            !menuOpen
                                                ? '/images/bar.svg'
                                                : '/images/close-hamburger.svg'
                                        }
                                        alt={!menuOpen ? 'bar' : 'close'}
                                        className="relative w-6 h-6"
                                    />
                                </div>
                            </div>
                            <div
                                className={`${
                                    menuOpen
                                        ? 'mobile-menu-visible block '
                                        : 'mobile-menu-hidden hidden'
                                } fixed top-12 md:top-16 right-0 z-50 mobile-menu min-width-full md:w-[350px]`}
                            >
                                <div
                                    className="relative flex flex-col justify-between w-screen pb-20 bg-basicWhite md:w-full backdrop-blur-lg mobile-menu-content"
                                    style={{ height: height }}
                                >
                                    <ul className="px-5 overflow-y-auto">
                                        {menuItems.map((item, index) => {
                                            {
                                                return (
                                                    <li key={item.name}>
                                                        <HamburgerMenuItem
                                                            menuItem={item}
                                                            closeMenu={() =>
                                                                setMenuOpen(
                                                                    false
                                                                )
                                                            }
                                                            selected={
                                                                index ===
                                                                openMenuIndex
                                                            }
                                                            setSelected={(
                                                                val
                                                            ) =>
                                                                setOpenMenuIndex(
                                                                    val
                                                                        ? index
                                                                        : null
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <ul className="flex items-center gap-4">
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <HeaderMenuItem
                                            menuItem={menuItem}
                                            selected={index === openMenuIndex}
                                            setSelected={(val) =>
                                                setOpenMenuIndex(
                                                    val ? index : null
                                                )
                                            }
                                            openMenuIndex={openMenuIndex}
                                            index={index}
                                            setOpenMenuIndex={setOpenMenuIndex}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default HomeNavbar;
