import Image from '@atoms/Image';
import Text from '@atoms/Text';

const PotentialCard = ({ cardData }) => {
    const { title, icon } = cardData;
    return (
        <li className="bg-primary-10 px-3 py-6 md:px-4 flex items-center gap-6 md:gap-4 rounded-2xl  xl:col-span-1 backdrop-blur-md">
            <div className="flex items-center justify-center w-10 h-10">
                <Image src={`/images/homepage/${icon}`} className="w-full h-full" />
            </div>
            <Text
                content={title}
                className="p4-regular text-basicBlack xl:max-w-[254px]"
            />
        </li>
    );
};

export default PotentialCard;
