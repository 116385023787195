import { brandName } from "./constants";

export const HeadTagData = {
    '/': {
        title: `Financial Advisors | Become a Partner | ${brandName}`,
        metaTitle: `Financial Advisors | Become a Partner | ${brandName}`,
        metaDesc: `Boost your earnings while diversifying your client's investment portfolio with an array of curated alternative fixed-income investment opportunities.`
    },
    '/all-investors': {
        title: `IFA | All Investors`,
    },
    '/reinvestments': {
        title: `IFA | All Investors`,
    },
    '/all-opportunities': {
        title: `IFA | All Opportunities`,
    },
    '/dashboard': {
        title: `IFA | Dashboard`,
    },
    '/invited-investors': {
        title: `IFA | Invited Investors`,
    },
    '/onboard-investor': {
        title: `IFA | Onboard Investor`,
    },
    '/reports': {
        title: `IFA | Reports`,
    },
    '/referrals': {
        title: `IFA | My Referrals`,
    },
    '/refer-and-earn': {
        title: `Refer a Partner`,
        metaTitle: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaDesc: `Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, `
    },
    '/dashboard/#InvestorDetails': {
        title: `Investor | Dashboard | ${brandName}`,
        metaTitle: `All your investments at one place on ${brandName} wealth platform! View your complete portfolio of fixed income instruments with ${brandName}.`,
        metaDesc: `Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,`
    },
};
