import { Image, Link, Text } from '@atoms/index';
import { brandName, footerLinks } from '@helpers/constants';

const Footer = () => {
    return (
        <div className={`bg-primary-600 z-40`}>
            <div className="mx-auto max-w-site-width p-4 md:p-6 xl:px-18">
                <div className="flex flex-col md:flex-row md:justify-between items-center gap-y-4 mb-3 md:mb-0">
                    <div className='flex-col flex gap-2 w-full md:w-auto'>
                    <div className={`w-[112px] h-6 mx-auto md:mx-0`}>
                        <Image
                            src={'/images/altGraaf-logo-white.svg'}
                            alt={`${brandName}-logo`}
                        />
                    </div>
                    <div className="h-[0.5px] w-full bg-primary-30 mt-4 mb-3 md:hidden" />
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-3 justify-center place-items-center md:place-items-start flex-wrap w-fit self-center">
                        {footerLinks.map((each) => {
                            return (
                                <Link href={each.url} target="__blank">
                                    <Text
                                        content={each.title}
                                        className="p6-medium text-basicWhite whitespace-nowrap text-center"
                                    />
                                </Link>
                            );
                        })}
                    </div>
                    </div>

                    <div className='hidden md:block'>
                        <Image class="w-20 h-20"
                            src={'/images/iso-certificate.webp'}
                            alt={'iso'}
                        />
                    </div>
                </div>

                <div className="h-[0.5px] w-full bg-primary-30 my-3 md:my-4 xl:my-6 hidden md:block" />

                <div className="flex flex-col-reverse md:flex-row md:justify-between items-center gap-y-2 text-primary-30 p6-regular">
                    <Text
                        content={`Copyright © ${new Date().getFullYear()}, ${brandName}. All rights reserved.`}
                    />
                    <div>
                        <Text
                            content={'Powered by AI Growth Private Limited'}
                            className={`md:inline text-center`}
                        />
                    </div>
                </div>

                <div className='flex md:hidden mt-4 justify-center items-center'>
                        <Image class="w-20 h-20"
                            src={'/images/iso-certificate.webp'}
                            alt={'iso'}
                        />
                </div>
            </div>
        </div>
    );
};

export default Footer;
