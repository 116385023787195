import { hasWindow } from '@services/http.service';
import { ADVISOR_LOGIN_URL, SEND_CONTEXT_OTP_URL, VERIFY_CONTEXT_OTP_URL } from '@services/urls/session';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { otpEntityTypeEnum } from '@ui/helpers/enum';

export const sendContextOTP = ({ mobile, context }, ctx = null) => {
    const details = { mobile, context, entityType: otpEntityTypeEnum.IFA };
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details, ctx);
};

export const resendContextOTP = ({ mobile, context }, ctx = null) => {
    const details = {
        mobile,
        context,
        entityType: otpEntityTypeEnum.IFA,
        resend: true
    };
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(SEND_CONTEXT_OTP_URL(), details, ctx);
};

export const verifyContextOtp = ({ mobile, context, otp }, ctx = null) => {
    const details = { mobile, context, otp, entityType: otpEntityTypeEnum.IFA, };
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(VERIFY_CONTEXT_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(VERIFY_CONTEXT_OTP_URL(), details, ctx);
};

export const loginAdvisor = ({ mobile, otpIdList }, ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(ADVISOR_LOGIN_URL(), {
            mobile, otpIdList
        });
    }
    return SsrHttpService.postWithOutAuth(ADVISOR_LOGIN_URL(), { mobile, otpIdList }, ctx);
};
