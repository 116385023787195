const Button = ({
    id,
    children,
    icon,
    onClick,
    isLoading,
    buttonType,
    textColor,
    bgColor,
    paddingClass = 'p-4',
    wrapperClass = '',
    ...property
}) => {
    return (
        <div className={`${wrapperClass}`}>
            {(() => {
                if (
                    property.design == 'standard' &&
                    property.type == 'primary'
                ) {
                    return (
                        <button
                            type={buttonType}
                            onClick={onClick}
                            className={`relative ${paddingClass} flex justify-center ${
                                textColor ? textColor : 'text-basicWhite'
                            }  rounded-lg ${
                                bgColor ? bgColor : 'bg-primary-500'
                            } text-${property.size}  ${property.className} ${
                                property.state == 'disabled' &&
                                'opacity-50 cursor-not-allowed'
                            }
                            ${property.borderClass ? property.borderClass : ''}
                            ${isLoading ? 'cursor-not-allowed' : ''}
                            `}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            id={id}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 border-basicWhite rounded-full left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
                if (property.design == 'standard' && property.type == 'ghost') {
                    return (
                        <button
                            type={buttonType}
                            onClick={onClick}
                            className={`relative ${paddingClass} flex border border-gray-600 rounded-lg justify-center text-${
                                property.size
                            }  ${property.className} ${
                                textColor ? textColor : 'text-primary-500'
                            }`}
                            id={id}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 rounded-full border-primary-500 left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
            })()}
        </div>
    );
};
export default Button;
