import Link from '@atoms/Link';
import Text from '@atoms/Text';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';

const SubmenuItem = ({ name, link, setOpenMenuIndex }) => {
    const [hover, setHover] = useState(false);
    return (
        <Link href={link} key={name} target="__blank">
            <div
                className={`px-4 py-3 font-semibold ${link
                    ? 'hover:bg-gray-50 text-gray-500 hover:text-primary-500'
                    : 'text-gray-200'
                    } flex items-center justify-between gap-2 cursor-pointer rounded-xl`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setOpenMenuIndex(null)}
            >
                <Text
                    content={name}
                    className="text-base whitespace-nowrap font-heading"
                />
                <ChevronRightIcon
                    className={`w-6 h-6 ${hover && link ? '' : 'invisible'}`}
                />
            </div>
        </Link>
    );
};

const Submenu = ({ menuItems, setOpenMenuIndex }) => {
    return (
        <div className="absolute font-paragraph top-4 -left-2 lg:left-0 shadow-lg translate-x-[-50%] bg-basicWhite p-3 rounded-xl flex flex-col gap-1 border">
            {menuItems.map((item, index) => (
                <SubmenuItem
                    setOpenMenuIndex={setOpenMenuIndex}
                    name={item.name}
                    link={item.link}
                    key={`${item.name}-${index}`}
                />
            ))}
        </div>
    );
};

export default Submenu;
