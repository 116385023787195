import { useEffect, useState, useRef, createRef } from 'react';
import { createPopper } from '@popperjs/core';
import { Image } from '@atoms/index';

const Dropdown = (props) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
    const btnDropdownRef = createRef();
    const popoverDropdownRef = createRef();
    const wrapperRef = useRef(null);

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: 'bottom-start',
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDropdownPopoverShow(false);
        }
    };

    return (
        <>
            <div className="w-full">
                <div className={`relative flex flex-col items-start w-full `}>
                    {props.label && <span>{props.label}</span>}
                    <button
                        className={`w-full flex justify-between items-center text-gray-400 bg-basicWhite text-base px-4 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 border`}
                        type="button"
                        ref={btnDropdownRef}
                        id="select-type-of-document"
                        onClick={() => {
                            dropdownPopoverShow
                                ? closeDropdownPopover()
                                : openDropdownPopover();
                        }}
                    >
                        <span id={props.selectedItems[0]}>
                            {!props.includeSelectAll &&
                            props.title &&
                            props.selectedItems.length > 0
                                ? props.selectedItems[0]
                                : props.title}
                        </span>
                        <Image
                            src="/images/caret-down.svg"
                            width="24"
                            height="24"
                        />
                    </button>
                    {props.error && (
                        <div className="py-2 text-semantic-error-base">
                            {props.error.message}
                        </div>
                    )}

                    <div
                        ref={popoverDropdownRef}
                        className={
                            (dropdownPopoverShow ? 'block ' : 'hidden ') +
                            'w-full text-base z-50 float-left list-none text-center rounded-lg shadow-lg mt-1 bg-basicWhite border h-auto max-h-80 overflow-auto'
                        }
                    >
                        {props.includeSelectAll && (
                            <div className="drop-down-item ">
                                <div className="drop-down-checkbox">
                                    <input
                                        type="checkbox"
                                        name="Select All"
                                        checked={props.selectAllChecked}
                                        onChange={(e) => {
                                            props.handleSelectAll(e);
                                        }}
                                    />
                                </div>
                                <p className="">Select All</p>
                            </div>
                        )}
                        {props.dropDownItems.map((dropDownItem) => (
                            <div
                                className="cursor-pointer drop-down-item"
                                key={dropDownItem.id}
                                onClick={(e) => {
                                    props.handleCheckBox(
                                        e,
                                        dropDownItem.name,
                                        dropDownItem.id
                                    );
                                    setDropdownPopoverShow(false);
                                }}
                                id={dropDownItem.name}
                            >
                                <p>{dropDownItem.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dropdown;
