import { UrlParamsReplace } from './index';

export const SEND_CONTEXT_OTP_URL = () => UrlParamsReplace('/otp/send');

export const VERIFY_CONTEXT_OTP_URL = () => UrlParamsReplace('/otp/verify');

export const ADVISOR_LOGIN_URL = () =>
    UrlParamsReplace('/financial-advisor-sessions');

export const REFRESH_SESSION = () => {
    return UrlParamsReplace('/refresh-sessions');
};
