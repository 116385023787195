import * as HttpService from '@services/http.service';
import { getAuth, setAuth } from '@services/identity.service';

export const postWithOutAuth = (url, entity) => {
    return HttpService.postWithOutAuthDecorator(url, entity);
};

export const getWithOutAuth = (url) => {
    return HttpService.getWithOutAuthDecorator(url);
};

export const postWithAuth = async (url, entity) => {
    let response = await HttpService.postWithAuthDecorator(
        url,
        entity,
        getAuth()
    );
    if (response?.token) {
        setAuth(response.token);
    }
    return response;
};

export const getWithAuth = async (url) => {
    let response = await HttpService.getWithAuthDecorator(url, getAuth());
    if (response?.token) {
        setAuth(response.token);
    }
    return response;
};

export const deleteWithAuth = async (url, entity) => {
    let response = await HttpService.deleteWithAuthDecorator(
        url,
        entity,
        getAuth()
    );
    if (response?.token) {
        setAuth(response.token);
    }
    return response;
};

export const putWithAuth = async (url, entity) => {
    let response = await HttpService.putWithAuthDecorator(
        url,
        entity,
        getAuth()
    );
    if (response?.token) {
        setAuth(response.token);
    }
    return response;
};
