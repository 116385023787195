/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import { getLayoutConfig } from '@components/Layout/LayoutConfiguration';
import dynamic from 'next/dynamic';
const WithAuth = dynamic(() => import('@components/Auth/WithAuth'), {
    ssr: true,
    csr: true,
});
const ToastContainer = dynamic(() =>
    import('react-toastify').then((mod) => mod.ToastContainer)
);

function MyApp({ Component, pageProps, router }) {
    let layoutConfig = getLayoutConfig(router.pathname);
    const { layout: FetchedLayout, overrides } = layoutConfig;
    return (
        <WithAuth router={router}>
            <FetchedLayout
                children={<Component {...pageProps} />}
                {...overrides}
            />

            <ToastContainer />
        </WithAuth>
    );
}

export default MyApp;
