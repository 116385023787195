import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const Paginator = ({
    onClick,
    currentPage,
    totalRecords,
    pageSize,
    paginatorSize,
    backgroundColor = `bg-primary-500`,
    textColor = 'text-primary-700',
    hoverTextColor = 'text-basicWhite',
    border = 'border border-gray-400',
    spacing = 'mx-1 md:mx-[6px]',
}) => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const [paginatorButtonTexts, setPaginatorButtonTexts] = useState([]);
    if (paginatorSize > totalPages) {
        paginatorSize = totalPages;
    }

    const goToPage = (pageNumber) => {
        if (
            pageNumber >= 1 &&
            pageNumber <= totalPages &&
            pageNumber != currentPage
        )
            onClick(pageNumber);
    };

    const currentPageIsHigherThanPaginatorSize = () =>
        currentPage > paginatorSize;
    const paginatorButtonTextsIncludeCurrentPage = () =>
        paginatorButtonTexts.find(
            (paginatorButtonText) => paginatorButtonText == currentPage
        );

    useEffect(() => {
        if (
            currentPageIsHigherThanPaginatorSize() &&
            !paginatorButtonTextsIncludeCurrentPage()
        ) {
            setPaginatorButtonTexts(
                [...Array(paginatorSize)].map(
                    (index, i) => currentPage + 1 - (paginatorSize - i)
                )
            );
        } else if (
            !currentPageIsHigherThanPaginatorSize() &&
            !paginatorButtonTextsIncludeCurrentPage()
        ) {
            setPaginatorButtonTexts(
                [...Array(paginatorSize)].map((index, i) => i + 1)
            );
        }
    }, [currentPage]);

    useEffect(() => {
        setPaginatorButtonTexts(
            [...Array(paginatorSize)].map((index, i) => i + 1)
        );
        onClick(1);
    }, [totalPages]);

    return (
        <div className="flex items-center justify-center">
            <button
                className={`px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 outline-none ${textColor} hover:${backgroundColor} hover:${hoverTextColor} active:${backgroundColor} focus:outline-none ${border} ${spacing} rounded-md`}
                type="button"
                onClick={() => goToPage(1)}
            >
                <FontAwesomeIcon className="icon" icon={faAngleDoubleLeft} />
            </button>
            <button
                className={`px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 outline-none ${textColor} hover:${backgroundColor} hover:${hoverTextColor} active:${backgroundColor} focus:outline-none ${border} ${spacing} rounded-md`}
                type="button"
                onClick={() => goToPage(currentPage - 1)}
            >
                <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            </button>
            {paginatorButtonTexts.map((value, i) => (
                <button
                    key={i}
                    className={`${currentPage == value
                        ? `${backgroundColor} cursor-text text-basicWhite`
                        : `bg-gray-100 ${textColor} ${border}`
                        } ${spacing} hover:${backgroundColor} hover:${hoverTextColor} active:${backgroundColor} font-semibold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 rounded-md`}
                    type="button"
                    onClick={() => goToPage(value)}
                >
                    {value}
                </button>
            ))}
            <button
                className={`px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 outline-none ${textColor} hover:${backgroundColor} hover:${hoverTextColor} active:${backgroundColor} focus:outline-none ${border} ${spacing} rounded-md`}
                type="button"
                onClick={() => goToPage(currentPage + 1)}
            >
                <FontAwesomeIcon className="icon" icon={faAngleRight} />
            </button>
            <button
                className={`px-4 py-2 mb-1 text-xs font-semibold uppercase transition-all duration-150 ease-linear bg-gray-100 rounded-r outline-none ${textColor} hover:${backgroundColor} hover:${hoverTextColor} active:${backgroundColor} focus:outline-none ${border} ${spacing} rounded-md`}
                type="button"
                onClick={() => goToPage(totalPages)}
            >
                <FontAwesomeIcon className="icon" icon={faAngleDoubleRight} />
            </button>
        </div>
    );
};

export default Paginator;
