const Option = ({ label, value }) => {
    return (
        <>
            <option value={value} id={label.replace(/ +/g, '_')}>
                {' '}
                {label}{' '}
            </option>
        </>
    );
};

export default Option;
