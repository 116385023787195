import Image from 'next/legacy/image';
import { useEffect } from 'react';
const Modal = ({
    show,
    onClose,
    label,
    width,
    height,
    hideTitle,
    children,
    labelSize,
    childrenPadding = 'p-6',
    background = 'bg-basicWhite',
    ...property
}) => {
    const yScroll = height ? `overflow-y-auto ${height}` : '';
    const lableFontSize = labelSize ? labelSize : 'text-xl md:text-2xl';

    useEffect(() => {
          if(show) document.getElementsByTagName("body")[0].style.overflow="hidden"; 
          else document.getElementsByTagName("body")[0].style.overflow="auto";   
          // clean up
          return () => {
            document.getElementsByTagName("body")[0].style.overflow="auto";  
          }
    }, [show])

    return (
        <>
            <div
                className={`fixed top-0 left-0 flex items-center justify-center w-full h-full modal overflow-y-auto z-50 ${!show && 'hidden'
                    }`}
            >
                <div className="absolute w-full h-full bg-primary-700 opacity-50 modal-overlay"></div>

                <div
                    className={`text-basicBlack w-full mx-4 md:mx-0 ${width}  rounded-lg shadow-lg z-50 overflow-y-auto ${background}`}
                >
                    {!hideTitle && (
                        <div className="flex items-center justify-between p-5 border-b">
                            <p
                                className={`font-semibold text-gray-800  ${lableFontSize}`}
                            >
                                {label}
                            </p>
                            <div className="z-50 cursor-pointer modal-close">
                                <button onClick={onClose}>
                                    <Image
                                        src="/images/close.svg"
                                        width="20"
                                        height="20"
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                    <div
                        className={`${childrenPadding} ${property.className} ${yScroll}`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
