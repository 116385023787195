import dynamic from 'next/dynamic';
const EmptyLayout = dynamic(() => import('./EmptyLayout'));
const NormalLayout = dynamic(() => import('./NormalLayout'));
const DashboardLayout = dynamic(() => import('./DashboardLayout'));
const OnboardInvestorLayout = dynamic(() => import('./OnboardInvestorLayout'));
const ReferralLayout = dynamic(() => import('./ReferralLayout'));

const LayoutConfig = [
    {
        path: '/dashboard',
        layout: DashboardLayout,
    },
    {
        path: '/onboard-investor',
        layout: OnboardInvestorLayout,
    },
    {
        path: '/investors',
        layout: DashboardLayout,
    },
    {
        path: '/all-investors',
        layout: DashboardLayout,
    },
    {
        path: '/reinvestments',
        layout: DashboardLayout,
    },
    {
        path: '/invited-investors',
        layout: DashboardLayout,
    },
    {
        path: '/all-opportunities',
        layout: DashboardLayout,
    },
    {
        path: '/reports',
        layout: DashboardLayout,
    },
    {
        path: '/refer-and-earn',
        layout: ReferralLayout,
    },
    {
        path: '/referrals',
        layout: DashboardLayout,
    },
    {
        path: '/opportunity',
        layout: DashboardLayout,
    },
    {
        path: '',
        layout: NormalLayout,
    },
    {
        path: '/invitation',
        layout: NormalLayout,
    },

];

const getLayoutConfig = (path) => {
    let config = LayoutConfig.find((configItem) =>
        path.includes(configItem.path)
    );
    if (config) return config;
    else return { layout: EmptyLayout };
};

export { getLayoutConfig };
