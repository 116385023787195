import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Text, Link, Card, Image } from '@atoms/index';
import { loginAdvisor, resendContextOTP, verifyContextOtp } from '@services/session.service';
import { setAuth } from '@services/identity.service';
import { useRouter } from 'next/router';
import { Button } from '@ui/atoms/design-system';
import useOtpInput from '@ui/hooks/useOtpInput';
import { otpContextEnum } from '@ui/helpers/enum';
import { otpExpiredErrorWord, otpLength } from '@ui/helpers/constants';
import { maskEmail } from '@helpers/miscelleanous';
const VerifyOtpCard = ({ setShowVerifyOtpCard, mobileNumber, otpModes }) => {
    const otpExpiresAfter = 60;
    const [seconds, setSeconds] = useState(otpExpiresAfter);
    const [message, setMessage] = useState('');
    const [otpMessage, setOtpMessage] = useState('OTP sent successfully!');
    const [isResendButtonLoading, setIsResendButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const { otpValue, setOtpValue, OtpInputComponent, setDisabled, setError, inputRef } = useOtpInput({
        setMessage,
    });
    const timeoutRef = useRef();

    const { handleSubmit } = useForm();

    const editMobile = () => {
        setOtpValue('');
        setMessage('');
        setShowVerifyOtpCard(false);
    };

    const onSubmit = async () => {
        setDisabled(true);
        setLoading(true);
        const response = await verifyContextOtp({ mobile: mobileNumber, otp: otpValue, context: otpContextEnum.AUTHENTICATION, });
        if (response.status) {
            await login(response.entity);
        } else {
            setLoading(false);
            setMessage(response.message);
            setError(true);
            if (response.message[0].includes(otpExpiredErrorWord)) {
                setDisabled(true);
                setSeconds(0);
            } else {
                setDisabled(false);
            }
        }
    };

    const login = async (verifyOtpResponseEntity) => {
        const response = await loginAdvisor({ mobile: mobileNumber, otpIdList: [verifyOtpResponseEntity.id] });
        if (response.status) {
            const user = response.entity;
            setAuth(user);
            await router.push('/dashboard');
        } else {
            setMessage("There was an error logging in. Please 'Resend OTP' to try again.");
            setDisabled(true);
            setSeconds(0);
        }
        setLoading(false);
    }

    const resendOtp = async () => {
        setOtpValue('');
        setMessage('');
        setError(false);
        setIsResendButtonLoading(true);
        const response = await resendContextOTP({ mobile: mobileNumber, context: otpContextEnum.AUTHENTICATION });
        if (response.status) {
            setMessage('');
            setOtpMessage('OTP sent successfully!');
            setSeconds(otpExpiresAfter);
            setDisabled(false);
            inputRef.current?.focus();
        } else {
            setOtpMessage('Failed to resend the OTP. Try again!');
        }
        setIsResendButtonLoading(false);
    };

    useEffect(() => {
        if (seconds > 0) {
            timeoutRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [seconds]);

    return (
        <Card
            className="w-[328px] sm:w-[350px] md:w-[411px] rounded-2xl"
            paddingClass="px-4 py-5"
            borderRadius="border border-gray-100"
            verticalSpace=""
            shadow=""
            boxShadow="0px 6px 18px 0px rgba(11, 7, 110, 0.07) "
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4 text-center">
                    <Text
                        content={`Enter the ${otpLength} digit OTP sent to you at`}
                        className="p5-medium text-gray-900 mb-2"
                    />
                    <Text
                        content={`+91 ${mobileNumber.slice(
                            2,
                            mobileNumber.length
                        )}`}
                        className="inline pr-4 p5-medium text-gray-900"
                    />
                    <span onClick={editMobile} className="cursor-pointer">
                        <Image
                            src="/images/edit.svg"
                            width="16"
                            height="17"
                            className="inline"
                        />
                    </span>
                    <div>
                        <Text
                            content={maskEmail(otpModes?.find(val => val.name === 'email')?.to)}
                            className="inline p5-medium text-gray-900"
                        />
                    </div>
                </div>
                <div className="w-full text-center">
                    <div className="flex justify-center my-1 md:my-3">
                        {OtpInputComponent}
                    </div>
                    <Button
                        id="verifyOtpButton"
                        buttonType="primary"
                        widthClass="w-full"
                        className="mt-4"
                        isLoading={loading}
                        disabled={(otpValue.length !== otpLength || !!message) && 'disabled'}
                        buttonText="Verify"
                    />
                    {otpMessage !== '' && message === '' && (
                        <Text
                            className="pt-3 p5-medium text-secondary-600"
                            content={otpMessage}
                        />
                    )}
                    {message && (
                        <Text
                            content={message}
                            className="pt-3 p5-medium text-semantic-error-base"
                        />
                    )}
                    <div className="pt-1">
                        <div className='w-full flex justify-center items-center'>
                            <Button buttonText={"Resend OTP"}
                                onClick={resendOtp}
                                buttonType={'link'}
                                className={`${seconds === 0
                                    ? 'text-primary-500 block'
                                    : 'hidden'
                                    }`}
                                buttonSize={'extrasmall'}
                                isLoading={isResendButtonLoading} />
                        </div>
                        <div className=" w-full">
                            {seconds != 0 && (
                                <Text
                                    className="p5-regular text-gray-500"
                                    content={`Resend OTP in 00:${seconds < 10
                                        ? `0${seconds}`
                                        : `${seconds}`
                                        } Seconds`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default VerifyOtpCard;
