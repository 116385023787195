import Image from './Image';

const EmptyContainer = ({ imgSrc, primaryText, secondaryText, height }) => {
    return (
        <div
            className={`flex flex-col justify-center items-center ${height} font-paragraph gap-3 p-2 w-full`}
        >
            <Image src={imgSrc} alt="icon" />
            <div className="flex flex-col justify-center items-center gap-2">
                {primaryText ? (
                    <h2 className="text-lg text-center font-semibold">
                        {primaryText}
                    </h2>
                ) : null}

                {secondaryText ? (
                    <p className="text-sm text-gray-400 text-center">
                        {secondaryText}
                    </p>
                ) : null}
            </div>
        </div>
    );
};

export default EmptyContainer;
