import Cookies from "universal-cookie";
import {getAuthCtx, setCookies} from "@http/ssr-http.service";
import {refreshToken} from "@services/http.service";
import jwtDecode from "jwt-decode";


const cookies = new Cookies();

// TODO - Refactor the way we get auth post refresh tokens
export const getAuth = () => {
    return cookies.get("AUTH");
};

// TODO - Refactor the way we set auth post refresh tokens
export const setAuth = (authObject) => {
    cookies.set("AUTH", JSON.stringify(authObject), {path: "/"});
    return authObject;
};

export const removeAuth = () => {
    return cookies.remove("AUTH", {path: "/"});
};

export const isAuthenticated = async (user, ctx = null) => {
    return user != null && user.token && await verifyToken(ctx);
};

export const isUnauthorizedRequest = async (auth, ctx = null) => {
    return !auth || !await isAuthenticated(JSON.parse(auth), ctx);
};

const verifyToken = async (ctx) => {
    try {
        if (ctx) {
            let currentTimestamp = new Date().getTime() / 1000;
            const authCtx = getAuthCtx(ctx);
            const decoded = jwtDecode(authCtx.token);
            const isExpired = decoded.exp < currentTimestamp
            if (isExpired) {
                const response = await refreshToken(authCtx);
                if (response.status) {
                    setCookies(ctx, {token: response.entity})
                }
                return response.status
            }
            return !isExpired;
        } else {
            let currentTimestamp = new Date().getTime() / 1000;
            const auth = getAuth();
            const decoded = jwtDecode(auth.token);
            const isExpired = decoded.exp < currentTimestamp;
            if (isExpired) {
                const response = await refreshToken(auth);
                if (response.status) {
                    setAuth(response.entity)
                }
                return response.status
            }
            return !isExpired;
        }
    } catch (e) {
        return false
    }
};

export const getLogoutSsr = (ctx) => {
    /* 
        ctx.res.setHeader('Cache-Control', 'no-store') sets the Cache-Control header to 'no-store', 
        indicating that the response should not be stored or cached.
        By setting the cache control header in this way, you can prevent caching of the redirect response.
        Issue resolved: duplicate tab redirected to cache route
    */
    if (ctx) ctx.res.setHeader('Cache-Control', 'no-store');
    return {
        redirect: {
            permanent: false, destination: "/logout"
        }
    }
}
