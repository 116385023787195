import { hasWindow } from '@services/http.service';
import {
    GET_EXISTING_ADVISOR_ACCOUNT_URL,
    POST_LOGOUT_USER_URL,
    GET_IS_USER_VALID_TO_ONBOARD_URL,
} from '@services/urls/user';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';

export const validateAdvisor = (mobile) => {
    const url = GET_EXISTING_ADVISOR_ACCOUNT_URL(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const logoutUser = (data, ctx = null) => {
    const url = POST_LOGOUT_USER_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const validate = (mobile, financialAdvisorId) => {
    const url = GET_IS_USER_VALID_TO_ONBOARD_URL(mobile, financialAdvisorId);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};
