export const reInvestmentLineStatusEnums = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    SUSPEND: 'Suspend',
    IN_PROGRESS: 'InProgress',
    DROPPED: 'Dropped',
    AUTO_REINVESTMENT_IN_PROGRESS: 'AutoReInvestmentInProgress',
    ACTIVE_FROZEN: 'ActiveFrozen',
    SUSPEND_FROZEN: 'SuspendFrozen'
};

