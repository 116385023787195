const Avatar = ({ icon, ...property }) => {
    return (
        <div
            className={`w-8 h-8 inline-block text-center leading-8 ${property.className}`}
        >
            <div className="flex items-center justify-center w-full h-full overflow-hidden text-center rounded-full shadow-inner cursor-pointer bg-primary-30">
                <span className="p4-medium align-middle text-primary-500 group-hover:table-cell">
                    {icon}
                </span>
            </div>
        </div>
    );
};

export default Avatar;
