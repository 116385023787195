import { ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { Image, Link, Text } from '@atoms/index';

const HamburgerMenuItem = ({ menuItem, closeMenu }) => {
    const { name, submenu, link } = menuItem;

    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <div
                className={`border-b-[1px] border-gray-200 flex justify-between py-5 text-primary-500 hover:text-primary-300 gap-x-2`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <Text
                    content={name}
                    className="text-base font-semibold text-gray-500"
                />
                <ChevronDownIcon
                    className={`w-5 translate-all duration-100  text-gray-500 ${
                        isOpen ? 'rotate-180' : ''
                    }`}
                />
            </div>
            <ul>
                {isOpen &&
                    submenu.map((item) => {
                        return (
                            <li className={`p-3`}>
                                <Link
                                    href={item.link}
                                    className={`text-base ${
                                        item.link
                                            ? 'text-gray-500 hover:text-primary-500'
                                            : 'text-gray-300'
                                    } font-semibold`}
									target="__blank"
                                >
                                    {item.name}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
export default HamburgerMenuItem;
