const Input = ({
    id,
    register,
    type,
    placeholder,
    label,
    labelClass,
    error,
    onClick,
    onPaste,
    onBlur,
    width,
    height,
    wrapperClass,
    ...inputProps
}) => {
    return (
        <div className={`${wrapperClass}`}>
            {label && (
                <label
                    className={`block w-full text-left text-gray-900 ${labelClass}`}
                >
                    {label}
                </label>
            )}
            {inputProps.chipLabel && (
                <label
                    className={`block w-full text-left text-gray-500 ${inputProps.chipLabelClass}`}
                >
                    {inputProps.chipLabel}
                </label>
            )}
            <input
                {...register(id)}
                id={id}
                {...inputProps}
                type={type}
                onClick={onClick}
                onPaste={onPaste}
                onBlur={onBlur}
                className={`${
                    error ? 'border-semantic-error-base' : 'border-gray-300'
                } border-2 ${width ? width : 'w-full'} ${
                    height ? height : 'h-12'
                } px-4 py-3 rounded-lg mt-2 box-border ${inputProps.className}`}
                placeholder={placeholder}
            />
            {error && (
                <div className="text-semantic-error-base text-left">{error.message}</div>
            )}
        </div>
    );
};
export default Input;
