export const reInvestmentStatusEnums = {
    OPPORTUNITY_NOT_AVAILABLE: 'OpportunityNotAvailable',
    INVESTOR_CALLED_OFF: 'InvestorCalledOff',
    ESIGN_MISSED: 'ESignMissed',
    ESGIN_COMPLETED: 'ESignCompleted',
    INVESTMENT_COMPLETED: 'InvestmentCompleted',
    ESGIN_PENDING: 'ESignPending',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
    REINVESTMENT_COMPLETED: 'ReinvestmentCompleted',
};

