import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HomeNavbar from '@molecules/HomeNavbar';
import Footer from '@components/homePage/Footer';
import useScrollToTopOnRouteChange from 'jiraaf-ui/hooks/useScrollToTopOnRouteChange';
import { useRouter } from 'next/router';
import useBroadcastChannel from '@hooks/useBroadcastChannel';
import {
    listenBroadCastMessage,
} from '@helpers/miscelleanous';

const NormalLayout = ({ children }) => {
    useScrollToTopOnRouteChange();
    const router = useRouter();
    useBroadcastChannel('logout-event', (log) => {
        // Clear user-related data, redirect to the login page, etc.
        listenBroadCastMessage(log, 'reload', router);
    });

    return (
            <div className="flex flex-col min-h-screen bg-basicWhite overflow-hidden">
                <HomeNavbar onBoard={false} />
                <div className="flex flex-col flex-1 item-center w-full mx-auto max-w-site-width">
                    {children}
                </div>
                <Footer />
            </div>
    );
};

NormalLayout.propTypes = {
    children: PropTypes.node,
};

export default NormalLayout;
