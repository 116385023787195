import { UrlParamsReplace } from './index';

export const GET_EXISTING_ACCOUNT_URL = (mobile) =>
    UrlParamsReplace('/existing-account?mobile=:mobile', { mobile });

export const GET_EXISTING_ADVISOR_ACCOUNT_URL = (mobile) =>
    UrlParamsReplace('/existing-advisor-account?mobile=:mobile', { mobile });

export const POST_LOGOUT_USER_URL = () => UrlParamsReplace('/v2/logout');
export const GET_IS_USER_VALID_TO_ONBOARD_URL = (mobile, financialAdvisorId) =>
    UrlParamsReplace(
        '/user-is-valid-to-onboard?mobile=:mobile&financialAdvisorId=:financialAdvisorId',
        { mobile, financialAdvisorId }
    );
