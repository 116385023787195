import { useEffect, useState } from 'react';
import Card from '@atoms/Card';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useRouter } from 'next/router';
import { Image, Text } from '@atoms/index';
import { getAuth, isAuthenticated } from '@services/identity.service';
import { validateAdvisor } from '@services/users.service';
import { sendContextOTP } from '@services/session.service';
import { theme } from 'tailwind.config';
import { Button } from '@ui/atoms/design-system';
import { partnersEmail } from '@helpers/constants';
import { otpContextEnum } from '@ui/helpers/enum';
import { handleKeyDown } from '@helpers/miscelleanous';

const LoginCard = ({ setShowVerifyOtpCard, mobileNumber, setMobileNumber, highlightLoginCard, setOtpModes }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mobile, setMobile] = useState('');
    const [isMobileValid, setIsMobileValid] = useState(false);
    const [isMobileNumberExists, setIsMobileNumberExists] = useState(false);
    const [mobileErrorMessage, setMobileErrorMessage] = useState('');
    const [countryCode, setCountryCode] = useState('91');

    const router = useRouter();
    const phoneInputStyle = {
        containerStyle: {
            borderRadius: '8px',
            borderWidth: '2px',
            height: '48px',
            padding: '0px',
            borderColor: theme?.colors?.gray?.[200],
        },
        inputStyle: {
            width: '100%',
            height: '100%',
            borderWidth: '0px',
            fontSize: '16px',
            paddingLeft: '4rem',
            borderRadius: '8px',
            fontWeight: '500',
            backgroundColor: theme?.colors?.basicWhite,
        },
        buttonStyle: {
            borderWidth: '0px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '3.5rem',
        },
        dropdownStyle: {
            overflow: 'auto',
            height: '92px',
        },
    };
    const mobileNumberLength = (code) => {
        switch (code) {
            case '971':
                return 9;
            case '65':
                return 8;
            default:
                return 10;
        }
    };

    const submitData = async () => {
        setLoading(true);
        const response = await sendContextOTP({ mobile, context: otpContextEnum.AUTHENTICATION });
        setLoading(false);
        if (response.status) {
            setMobileNumber(mobile);
            setShowVerifyOtpCard(true);
            setOtpModes(response.entity);
        } else {
            setMessage(response.message);
        }
    };

    const formSubmit = async () => {
        if (isMobileValid && isMobileNumberExists) {
            await submitData();
        }
    };

    useEffect(() => {
        (async () => {
            let isValid = false;
            setMobileErrorMessage('');
            if (
                mobile.length - countryCode.length ===
                mobileNumberLength(countryCode)
            ) {
                isValid = true;
            }
            setIsMobileValid(isValid);
            if (!isValid) {
                setIsMobileNumberExists(false);
            } else {
                const response = await validateAdvisor(mobile);
                setIsMobileNumberExists(response.status);
                setMobileErrorMessage(
                    response.status || 'Mobile number is not registered'
                );
            }
            const auth = getAuth();
            if (await isAuthenticated(auth)) {
                router.push('/dashboard');
            }
        })();
    }, [mobile, countryCode]);

    const mobileValidation = async () => {
        if (!isMobileValid) {
            setIsMobileNumberExists(false);
            if (mobile.length > countryCode.length)
                setMobileErrorMessage(
                    'Please enter a valid phone number'
                );
            else setMobileErrorMessage('');
        }
    }

    return (
        <Card
            className="w-[328px] sm:w-[350px] md:w-[411px] rounded-2xl"
            paddingClass="px-4 py-5"
            borderRadius="border border-gray-100"
            verticalSpace=""
            shadow=""
            boxShadow={highlightLoginCard ? "0px 0px 20px 8px #FFF0C4" : "0px 6px 18px 0px rgba(11, 7, 110, 0.07)"}
        >
            <>
                <div className="flex items-center gap-1 mb-2 md:mb-6">
                    <Text
                        content="Login"
                        className="h5-semibold text-primary-500"
                    />
                    <Text
                        content="(Existing Partners)"
                        className="p4-regular text-gray-500"
                    />
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formSubmit();
                    }}
                >
                    <Text
                        className={`w-full text-gray-900 p5-medium`}
                        content="Mobile Number"
                        htmlTag="label"
                    />
                    <div className='relative'>
                        <PhoneInput
                            country={'in'}
                            value={mobile}
                            onChange={(value, country) => {
                                setMobile(value);
                                setCountryCode(country?.dialCode);
                            }}
                            onKeyDown={(e) => handleKeyDown(e, () => setMobile(countryCode), () => formSubmit())}
                            placeholder="Enter Phone Number"
                            onlyCountries={['in']}
                            inputClass={
                                'px-5 h-full p5-regular !border-gray-200 border'
                            }
                            containerClass={
                                'h-12 mb-1 mt-2 flex w-full border-gray-200 border'
                            }
                            buttonClass={'!bg-basicWhite flag-container'}
                            containerStyle={phoneInputStyle.containerStyle}
                            inputStyle={phoneInputStyle.inputStyle}
                            buttonStyle={phoneInputStyle.buttonStyle}
                            disableDropdown={true}
                            countryCodeEditable={false}
                            dropdownStyle={phoneInputStyle.dropdownStyle}
                            dropdownClass={'w-full country-list-container'}
                            inputProps={{ required: true, id: 'mobileNo' }}
                            onBlur={mobileValidation}
                        />
                        <Image src={`/images/Indian-flag.svg`} className="absolute top-3 left-3 w-10 h-[26px]" />
                    </div>
                    {mobileErrorMessage && (
                        <Text
                            className="mb-2 p5-medium text-semantic-error-base"
                            content={mobileErrorMessage}
                        />
                    )}
                    {message && (
                        <Text
                            className="mb-2 p5-medium text-semantic-error-base"
                            content={message}
                        />
                    )}

                    <div className="mb-3">
                        <Button
                            id="loginButton"
                            buttonType="primary"
                            type="submit"
                            widthClass="w-full"
                            className="mt-4"
                            isLoading={loading}
                            disabled={!isMobileNumberExists}
                            buttonText="Login"
                        />

                        <Button
                            type='button'
                            buttonType='primary'
                            buttonText='Register Now'
                            widthClass='w-full'
                            className='mt-3'
                            disabled={isMobileNumberExists}
                            onClick={() => {
                                window.open('https://forms.gle/AyV4zfuD1RkcRLih7', '_blank')
                            }}
                            suffixComponent={<Image src='/images/link.svg' />}
                        />
                    </div>
                    <Text
                        className="p5-regular text-gray-600 text-center"
                        content={
                            <>
                                to become an empanelled partner, or write to us
                                at {' '} <br className='md:block hidden' />
                                <a
                                    href={`mailto:${partnersEmail}`}
                                    className="p5-underline text-primary-500"
                                >
                                    {partnersEmail}
                                </a>
                            </>
                        }
                    />
                </form>
            </>
        </Card>
    );
};

export default LoginCard;
