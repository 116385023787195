import { Text, Link, Image } from '@atoms/index';
import Submenu from './Submenu';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useRef } from 'react';
import useOnClickOutside from '@hooks/useOnClickOutside';

const HeaderMenuItem = ({
    menuItem,
    selected,
    setSelected,
    setOpenMenuIndex,
    openMenuIndex,
    index,
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        if (openMenuIndex === index) {
            setOpenMenuIndex(null);
        }
    });
    return (
        <>
            {menuItem.submenu ? (
                <div className="relative" ref={ref}>
                    <div
                        onClick={() =>
                            setOpenMenuIndex(!selected ? index : null)
                        }
                        className={` ${selected
                            ? 'text-primary-500'
                            : 'text-gray-500 hover:text-primary-500'
                            } font-semibold font-paragraph cursor-pointer flex flex-grow items-center justify-center`}
                    >
                        <Text
                            content={menuItem.name}
                            className="p4-medium max-w-min xl:max-w-max whitespace-nowrap"
                        />
                        {menuItem.submenu && (
                            <ChevronDownIcon
                                className={`h-5 w-5 transform-all duration-100 ${selected ? 'rotate-180' : ''
                                    }`}
                            />
                        )}
                    </div>
                    <div
                        className={`duration-100 translate-all ${selected
                            ? 'translate-y-0'
                            : 'invisible translate-y-2'
                            }`}
                    >
                        <Submenu
                            menuItems={menuItem.submenu}
                            setOpenMenuIndex={setOpenMenuIndex}
                        />
                    </div>
                </div>
            ) : (
                <div
                    onClick={() => setSelected(!selected)}
                    className="relative"
                >
                    <Link
                        href={menuItem.link}
                        className={`py-1 text-base ${selected
                            ? 'text-primary-500'
                            : 'text-gray-500 hover:text-primary-500'
                            } font-semibold cursor-pointer whitespace-nowrap flex items-center`}
                    >
                        {
                            menuItem.name
                        }
                    </Link>
                </div>
            )}
        </>
    );
};

export default HeaderMenuItem;
