import { OpportunityTypes } from '@ui/helpers/opportunities/opportunity-types';
import { theme } from 'tailwind.config';
import { brandNames } from '@ui/helpers/enums';

export const brandName = 'altGraaf';

export const navbarMenu = [
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'All investors', url: '/all-investors' },
    { title: 'Onboard Investor', url: '/onboard-investor' },
    { title: 'Invited Investors', url: '/invited-investors' },
    { title: 'Opportunities', url: '/all-opportunities' },
    { title: 'Reports', url: '/reports' },
    { title: 'My Referrals', url: '/referrals' },
];

export const WalletName = 'altSafe';

export const menu = [
    {
        labelName: 'All investors',
        iconSrc: '/images/darkProfile.svg',
        linkSrc: '/dashboard',
    },
    {
        labelName: 'Onboard Investor',
        iconSrc: '/images/addUser.svg',
        linkSrc: '/onboard-investor',
    },
];


export const opportunityPreferencesType = {
    OnlyForFirstTimeInvestor: 'First-time Investor',
    OnlyForNsdlInvestor: 'NSDL',
    OnlyForCdslInvestor: 'CDSL',
};

export const getActivePreferenceType = (
    opportunityPreferences,
    opportunityPreferencesType
) => {
    let preferencesType = [];
    opportunityPreferences.map((opportunityPreference) => {
        if (opportunityPreference.isActive) {
            preferencesType.push(
                opportunityPreferencesType[opportunityPreference.preferenceType]
            );
        }
    });

    return preferencesType;
};

export const panAlreadyExistMessage = `The PAN you have entered is already associated with another account on ${brandName}. You cannot have the same PAN associated with more than one account with us.`;

export const addressProofTypes = [
    { name: 'Aadhar Card', id: 'Aadhar' },
    { name: 'Voter Id', id: 'VoterId' },
    { name: 'Passport', id: 'Passport' },
];

export const opportunityClosedStates = [
    'OngoingRepayment',
    'PrepaidAndClosed',
    'MaturedAndClosed',
];

export const maxFileSize = 2000;
export const maxFileSizeFourMB = 4000;

export const INVESTOR_KYC_BUCKET = process.env.NEXT_PUBLIC_INVESTOR_KYC_BUCKET;

export const getHeaderAndDescriptionForDoubleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description: 'Your Details should be clearly readable',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description: 'Your Details should be clearly readable',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description: 'Your Details should be clearly readable',
            };
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png',
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png',
                },
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png',
                },
                back: {
                    header: 'Upload back side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png',
                },
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload Photo page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png',
                },
                back: {
                    header: 'Upload Address page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png',
                },
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png',
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png',
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png',
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'If you have a two seperate images for Aadhar and wish to upload each side separately, click',
        VoterId:
            'If you have a two seperate images for Voter ID and wish to upload each side separately, click',
        Passport:
            'If you have a two seperate images for Passport and wish to upload each side separately, click',
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: "Don't have a two-sided for Aadhaar?\nTo upload a single page Aadhaar click",
        VoterId:
            "Don't have a two seperated images for Voter ID?\nTo upload a single file of Voter ID click",
        Passport:
            "Don't have a two seperated images for Passport?\nTo upload a single file of Passport click",
    };
    return addressTypes[addressType];
};

export const allowedFileUploads =
    'image/png, image/jpeg, image/jpg, application/pdf, image/tiff';

export const getHeaderAndDescriptionForSingleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        default:
            break;
    }
};

export const defaultUploadedPdfIcon =
    '/images/investors/kyc/default-pdf-icon.svg';

export const confidenceScore = 0.65;

export const getCounterPartyAbout = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.VENTURE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
            return opportunity.borrower?.about;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.about;
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return opportunity.primaryCounterParty?.about;
        default:
            return opportunity.borrower?.about;
    }
};


export const getCounterPartyName = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.VENTURE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
            return opportunity.borrower?.name;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.name;
        case OpportunityTypes.INVOICE_DISCOUNTING: {
            if (opportunity?.brand?.name === brandNames.ALT_BLU) {
                return opportunity.anchor?.name;
            }
            return opportunity.primaryCounterParty?.name;
        }
        default:
            return opportunity.primaryCounterParty?.name;
    }
};
export const getCounterPartyLogo = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.VENTURE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
            return opportunity.borrower?.logo?.url;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.logo?.url;
        case OpportunityTypes.INVOICE_DISCOUNTING: {
            if (opportunity?.brand?.name === brandNames.ALT_BLU) {
                return opportunity.anchor?.logo?.url;
            }
            return opportunity.primaryCounterParty?.logo?.url;
        }
        default:
            return opportunity.primaryCounterParty?.logo?.url;
    }
};

export const opportunityBreadcrumbs = (value) => [
    { title: 'Opportunities', url: '/all-opportunities' },
    { title: value, active: true },
];

export const isUpcomingOpportunity = (opportunity) => {
    return opportunity.state === 'InDraft';
};
// DEPRICATED!
export const isInvoiceDiscountingOpportunity = (opportunityType) => {
    return opportunityType === 'InvoiceDiscounting';
};
// DEPRICATED!
export const isCorporateDebtOpportunity = (opportunityType) => {
    return opportunityType === 'CorporateDebt';
};
// DEPRICATED!
export const isVentureDebtOpportunity = (opportunityType) => {
    return opportunityType === 'VentureDebt';
};
// DEPRICATED!
export const isTreasuryBillOpportunity = (opportunityType) => {
    return opportunityType === 'TreasuryBill';
};

export const isCommercialDisplayAssetClass = (displayAssetClass) =>
    displayAssetClass && displayAssetClass.toLowerCase() === 'commercial paper';

export const INVESTMENT_AGGREGATION_CATEGORIES = [
    'ALL',
    'ACTIVE',
    'FULLY_REPAID',
];

export const getAssetClassColor = (className) => {
    switch (className) {
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return theme?.colors?.steelBlue?.[100];
        case OpportunityTypes.CORPORATE_DEBT:
            return theme?.colors?.pink?.[100];
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return theme?.colors?.mauve?.[100];
        case OpportunityTypes.VENTURE_DEBT:
            return theme?.colors?.skyBlue?.[100];
        case OpportunityTypes.TREASURY_BILL:
            return theme?.colors?.skin?.[100];
    }
};

const getFAQId = (string) =>
    string.split(' ').join('-').replace('&', 'and').toLowerCase();

export const referAndEarnFaq = [
    {
        header: 'Eligibility',
        id: getFAQId('Eligibility'),
        defaultOpen: true,
        questionBank: [
            {
                question: 'Who can participate in the referral program?',
                answer: `Any IFA partner who has empanelled on ${brandName} platform can refer their colleagues. However, we will transfer the referral reward only after KYC and bank information is completed.`,
            },
            {
                question:
                    'What would be my referral reward for referring 10 colleagues?',
                answer: (
                    <p>
                        IFA Partner referring 10 new colleagues would receive =
                        <ul className='pl-8 list-disc'>
                            <li>₹50,000 for referring 10 new partners who invest 10 lacs or above ((₹2000+₹3000) * 10)</li>
                            <li>₹20,000 for referring 10 new partners who invest less than 10 lacs (₹2000 * 10)</li>
                        </ul>
                    </p>
                ),
            },
            {
                question: 'What is the validity of the referral program?',
                answer: 'The program is valid from April 01, 2023 to June 30, 2023. To qualify for the rewards, at least one of your referral’s clients should have successfully invested ₹95,000 or above before June 30, 2023.',
            },
            {
                question: 'Can your referral partners be existing empanelled partners?',
                answer: `No, all referred colleagues need to be new to ${brandName} platform and should make at least one investment of ₹95,000 or above through one of their clients.`,
            },
            {
                question: 'When do I earn the referral reward?',
                answer: 'You will earn the reward once your colleague brings an investment of at least ₹95,000 or above through one of their clients.',
            },
            {
                question: 'When will I get my pay-out?',
                answer: 'All referral rewards that you have earned from April 1, 2023 to June 30, 2023 will be paid out on or before July 15, 2023.',
            },
            {
                question:
                    'What is the minimum amount of investment to be eligible for the program?',
                answer: 'The minimum amount of investment is ₹95,000.',
            },
            {
                question: 'How many people can I refer?',
                answer: 'There is no limit on the number of colleagues or friends that you can refer.',
            },
        ]
    }

];
// DEPRICATED!
export const unitBasedAssetClasses = [
    'CorporateDebt',
    'VentureDebt',
    'TreasuryBill',
    'CommercialPaper',
];
// DEPRICATED!
export const assetClassContainAmount = [
    'AssetBackedLeasing',
    'InvoiceDiscounting',
];
// DEPRICATED!
export const isUnitsBased = (type) => unitBasedAssetClasses.includes(type);
// DEPRICATED!
export const isAmountBased = (type) => assetClassContainAmount.includes(type);

export const OpportunityPreferenceTypes = {
    ALL_INVESTOR: 'AllInvestor',
    ONLY_FOR_FIRST_TIME_INVESTOR: 'OnlyForFirstTimeInvestor',
    ONLY_FOR_NSDL_INVESTOR: 'OnlyForNsdlInvestor',
    ONLY_FOR_CDSL_INVESTOR: 'OnlyForCdslInvestor',
    ZERO_AUM_INVESTOR: 'ZeroAUMInvestor',
    IFA_ONBOARDED_INVESTOR: 'IFAOnboardedInvestor',
    D2C_INVESTOR: 'D2CInvestor',
    REINVEST_DEAL: 'This is a reinvest deal'
};

// get preference tooltip message
export const getPreferenceTooltipMessage = (preferenceType) => {
    switch (preferenceType) {
        case OpportunityPreferenceTypes.ONLY_FOR_FIRST_TIME_INVESTOR:
            return 'First time investors';
        case OpportunityPreferenceTypes.ONLY_FOR_NSDL_INVESTOR:
            return 'Investors who have registered a NSDL demat account';
        case OpportunityPreferenceTypes.ONLY_FOR_CDSL_INVESTOR:
            return 'Investors who have registered a CDSL demat account';
        case OpportunityPreferenceTypes.ZERO_AUM_INVESTOR:
            return 'Investors with zero current AUM';
        case OpportunityPreferenceTypes.IFA_ONBOARDED_INVESTOR:
            return 'Investors who are onboarded by ifa';
        case OpportunityPreferenceTypes.D2C_INVESTOR:
            return 'Investors who are not onboarded by ifa';
        default:
            return `${preferenceType}`;
    }
};

export const InvestorListSortByOptions = [
    {
        value: 'name',
        label: 'Name',
    },
    {
        value: 'pan',
        label: 'Pan Number',
    },
    {
        value: 'date',
        label: 'Registration Date',
    },
    {
        value: 'status',
        label: 'Status',
    },
    {
        value: 'jsAmount',
        label: `${WalletName} Balance`,
    },
];
export const InvestmentListSortByOptions = [
    { label: 'Asset Class', value: 'displayAssetClass' },
    { label: 'Counter-Party', value: 'name' },
    { label: 'Investment Date', value: 'investmentDate' },
    { label: 'Amount Invested', value: 'investedAmount' },
    // { label: 'Total Gross Repaid Amount', value: 'name' },
    { label: 'Status', value: 'nextRepaymentStatus' },
    // { label: 'Expected IRR', value: 'name' },
    { label: 'Tenure', value: 'tenure' },
    // { label: 'Payment Frequency', value: 'name' },
    { label: 'Next Repayment Due Date', value: 'nextRepaymentDate' },
    { label: 'Next Repayment Amount', value: 'totalRepaymentAmount' },
    // { label: 'Repayment Schedule', value: 'name' },
];

export const getMobileNumberLength = (code) => {
    switch (code) {
        case '971':
            return 9;
        case '65':
            return 8;
        case '61':
            return 9;
        case '974':
            return 8;
        case '966':
            return 9;
        case '49':
            return [11, 12];
        case '33':
            return 9;
        case '60':
            return 9;
        default:
            return 10;
    }
};

export const userCountryCodesInput = [
    'in',
    'us',
    'gb',
    'ae',
    'sg',
    'au',
    'ca',
    'fr',
    'de',
    'my',
    'qa',
    'sa',
];

// mobile number length check
export const isValidMobileNumber = (value, country) => {
    if (value.length > country?.countryCode.length) {
        const mobileLength = getMobileNumberLength(country?.countryCode);
        if (typeof mobileLength === 'object') {
            // range length
            const digits = value.length - country.countryCode.length;
            if (
                mobileLength?.length >= 2 &&
                digits >= Number(mobileLength[0]) &&
                digits <= Number(mobileLength[1])
            ) {
                return true;
            }
        } else if (value.length - country.countryCode.length === mobileLength) {
            return true;
        }
    }
    return false;
};

export const partnersEmail = 'partners@altgraaf.com';

const UiURL = process.env.NEXT_PUBLIC_UI_URL

export const menuItems = [
    {
        name: 'Products',
        submenu: [
            {
                name: 'Corporate Debt (Unlisted)',
                link: `${UiURL}/products/unlisted-corporate-debt`,
            },
            {
                name: 'Venture Debt',
                link: `${UiURL}/products/venture-debt`,
            },
            {
                name: 'Invoice Discounting',
                link: `${UiURL}/products/invoice-discounting`,
            },
            {
                name: 'Asset Backed Leasing',
                link: `${UiURL}/products/asset-backed-leasing`,
            },
        ],
    },
    {
        name: 'About Us',
        submenu: [
            {
                name: 'Our Company',
                link: `${UiURL}/about-us#`,
            },
            {
                name: 'The Journey',
                link: `${UiURL}/about-us#milestones`,
            },
            {
                name: 'Values',
                link: `${UiURL}/about-us#values`,
            },
            {
                name: 'Investors',
                link: `${UiURL}/about-us#investors`,
            },
            //Hide this for now
            // {
            //     name: 'The Team',
            //     link: `${UiURL}/team`,
            // },
        ],
    },
];

export const footerLinks = [
    {
        title: 'Terms of use',
        url: `${UiURL}/terms-and-condition`,

    },
    {
        title: 'Privacy Policy',
        url: `${UiURL}/privacy-policy`,

    },
    {
        title: 'Disclaimer',
        url: `${UiURL}/disclaimer`
    },
    {
        title: 'Risk Disclosure',
        url: `${UiURL}/risk-disclosure`,

    },
];

export const ReportDownloadingMessage = "Please wait we are collecting all the download. The process might take longer.";

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*\.[a-zA-Z]{2,5}$/;