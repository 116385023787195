import { useState } from 'react';
import Image from './Image';

const ToolTip = ({
    title = <Image src="/images/info.svg" className="ml-2" />,
    content,
    children,
    width = 'w-72 md:w-122',
    position = 'absolute top-6 md:top-8 ml-2 sm:ml-0 left-auto right-auto',
    ...property
}) => {
    const [isShow, setIsShown] = useState(false);
    return (
        <div className="relative flex flex-col items-center group">
            {isShow && (
                <div
                    className={`${position} z-30 flex flex-col mb-6 ${width}  border rounded-lg border-gray-200 bg-basicWhite overflow-hidden drop-shadow-lg filter"`}
                >
                    <span className="relative p-2 rounded-lg bg-basicWhite  text-clip whitespace-no-wrap shadow-lg ">
                        {content}
                    </span>
                </div>
            )}
            <span
                onTouchStart={() => setIsShown(true)}
                onTouchEnd={() => setIsShown(false)}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
            >
                {title}
            </span>
        </div>
    );
};

export default ToolTip;
